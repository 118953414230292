import React from "react";
import Prepare from "../components/Faq/prepare";
import WhereToBuyUs from "../components/WhereToBuyUs/wheretobuyus";
export const faqData = [
  {
    question: "Do FIRST PRIDE products contain meat?",
    answer:
      "All FIRST PRIDE products are made from plants and do not contain any animal meat or derivatives.",
    component: "",
  },
  {
    question: "What are FIRST PRIDE products made of?",
    answer:
      "Our products are made of a special blend created by FIRST PRIDE. This blend comprises of 4 main ingredients: soy, wheat, citrus fiber, and bamboo fiber. It is what gives FIRST PRIDE products our natural, satisfying textures.",
    component: "",
  },
  {
    question: "Does FIRST PRIDE comply with Buddhist vegetarianism?",
    answer:
      "There are many different interpretations of Buddhist vegetarianism, depending on the follower. Please note that FIRST PRIDE products may contain garlic and onion powder, which may or may not comply to the different interpretations.",
    component: "",
  },
  {
    question: "Do FIRST PRIDE products contain gluten?",
    answer: "Yes. FIRST PRIDE products contain gluten.",
    component: "",
  },
  {
    question: "Are FIRST PRIDE products Halal?",
    answer: "Yes. All FIRST PRIDE products.",
    component: "",
  },
  {
    question: "How much are FIRST PRIDE products?",
    answer: "Our FIRST PRIDE’s products are <strong>4.25 SGD per pack.</strong>",
    component: "",
  },
  {
    question: "Where to buy FIRST PRIDE products?",
    answer: "You can find them at:",
    component: <WhereToBuyUs heading={false} />,
  },
  {
    question: "How many products are available at the moment?",
    answer:
      "There are currently 4 products available. \n\ni) <strong>FIRST PRIDE plant-based Popcorn Bites:</strong> Made with real popcorn flakes. Crunchily addictive.  \nii) <strong>FIRST PRIDE plant-based Crispy Nuggets:</strong> Tender and juicy nuggets with a thin, crispy coating. Tasty even without ketchup or other condiments.\niii) <strong>FIRST PRIDE plant-based Spicy Nuggets:</strong> Spice up the day with chunky nuggets coated in a mouth-watering herb and spice mix. \niv) <strong>FIRST PRIDE plant-based Crispy Strips:</strong> Tender, juicy and coated with a crispy batter, our 100% plant-based Strips are rich in protein and fiber, making them great for a snack or mealtime.",
    component: "",
  },
  {
    question: "What is the serving size per pack?",
    answer:
      "One pack is suitable for <strong>1-2 people.</strong> Delicious and healthy, they’re even more enjoyable when shared.",
    component: "",
  },
  {
    question: "How long can FIRST PRIDE products be stored?",
    answer:
      "FIRST PRIDE products are valid for 1 year from the manufacturing date. Once opened, they must be consumed within 3 months.",
    component: "",
  },
  {
    question: "What is the recommended temperature for storing?",
    answer:
      "It is recommended to store FIRST PRIDE products in the freezer at temperatures ranging from -18 to -23 degrees Celsius.",
    component: "",
  },
  {
    question: "How do I heat/prepare FIRST PRIDE products?",
    answer: "",
    component: <Prepare />,
  },
];
