import React from "react";
import Layout from "../components/Layout";
import { Accordion } from "react-bootstrap";
import { faqData } from "../data/faqData";
import "../scss/faq.scss";
import Helmet from "react-helmet";

const Faq = (props) => {
    return (
        <Layout title="FAQ" description="Curiousity is healthy. Get all your questions about FIRST PRIDE plant-based protein answered here.">
            <Helmet> 
                <link rel="alternate" href="https://www.firstpride.sg/faq" hreflang="en-sg" />
                <link rel="alternate" href="https://www.firstpridethailand.com/faq/" hreflang="en-th" />
            </Helmet>
                <div className="faq">
                    <div className="faqBanner">
                        <div className="faqBannerContent">
                            <h1>FAQ</h1>
                            <h5>Got questions about our plant-based products?</h5>
                        </div>
                    </div>
                    <div className="faqContent">
                        <Accordion flush>
                            {faqData.map((data, i) => (
                                <Accordion.Item eventKey={i}>
                                    <Accordion.Header>{data.question}</Accordion.Header>
                                    <Accordion.Body style={{ whiteSpace: "pre-wrap" }} className="p-regular" >
                                        <p className="p-regular" dangerouslySetInnerHTML={{ __html: data.answer }}/>
                                        {data.component}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </div>
        </Layout>
    );
};

export default Faq;
