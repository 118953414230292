import React from "react";
import styled from "styled-components";
import "./wheretobuyus.scss";
import { onlinestoreData, retailstoresData } from "../../data/wheretoBuyData";
import GreenButton from "../Button/GreenButton";
import { Link } from "gatsby";
const HeadingOne = styled.h2`
  display: ${({ heading }) => (heading ? "inline-block" : "none")};
`;
const HeadingThree = styled.h3`
  margin-bottom: 3rem;
  color: ${({ color }) =>
    color === "orange" ? "var(--darkorange)" : "var(--darkgreen)"};
`;

const Wrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 2vw;
  @media screen and (max-width: 716px) {
    flex-direction: column;
  }
  .item {
    margin: 1rem 1rem 2rem 1rem;
    align-content: center;
    text-align: center;
    @media screen and (max-width: 716px) {
      margin: 10px 10px;
    }
  }
  .gallery-img {
    border-radius: 10px;
    width: 23.5vw;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 716px) {
      width: 218px;
    }
  }
`;
const WhereToBuyUs = (props) => {
  return (
    <div className="whereToBuyWrapper" style={{ marginTop: "2rem" }}>
      <HeadingOne heading={props.heading}>Where to buy us</HeadingOne>
      <div className="store onlineStore anchor" id="onlinestore">
        <HeadingThree color={props.color}>Online store</HeadingThree>
        <Wrapper>
          {onlinestoreData.map((image, i) => {
            return (
              <Link
                to={image.link}
                target="_blank"
                style={{ marginBottom: "2rem" }}
              >
                <div key={i} className="item">
                  <img loading="lazy"
                    src={image.img}
                    alt="Onlinestore"
                    className="gallery-img OnlinestoreLogo"
                  />
                </div>
              </Link>
            );
          })}
          <div className="btnBox">
            <GreenButton
              msg="Buy now"
              icon="cart"
              to="https://redmart.lazada.sg/first-pride-123975828/?spm=a2o42.pdp_revamp.0.0.41142181cPkVQw&m=redmart&type=brand&fbclid=IwAR1hy2aEbwC_1CYC7w3s-tAUA7SaxRnjI8MUE1fthWYyeAhpII35B9Au0mc"
              target="_blank"
            />
          </div>
        </Wrapper>
      </div>

      <div className="store retailStore anchor" id="retailstore">
        <HeadingThree color={props.color}>Retailer stores</HeadingThree>
        <Wrapper>
          {retailstoresData.map((image, i) => {
            return (
              <Link to={image.link} target="_blank">
                <div key={i} className="item">
                  <img loading="lazy"
                    src={image.img}
                    alt="retailstore"
                    className="gallery-img"
                  />
                </div>
              </Link>
            );
          })}
        </Wrapper>
      </div>
    </div>
  );
};

export default WhereToBuyUs;
