import React from "react";
import { productDetailData } from "../../data/productDetail";
import "./faqs.scss";
const Prepare = () => {
    return (
        <div className="prepare12">
            <div className="prepare12Grid">
                <div className="ssprepare">
                    <dl>
                        {productDetailData.map((item, i) => (
                            <div hidden={i > 3 ? true : false}>
                                <dt>
                                    {(item.title).replace("<br/>", "")}
                                    <br />
                                </dt>
                                <dd>
                                    <h6 dangerouslySetInnerHTML={{ __html: item.method1Name }}></h6>
                                    <p dangerouslySetInnerHTML={{ __html: item.method1desc }}></p>
                                    <h6>{item.method2Name}</h6>
                                    <p dangerouslySetInnerHTML={{ __html: item.method2desc }}></p>
                                    <h6>{item.method3Name}</h6>
                                    <p dangerouslySetInnerHTML={{ __html: item.method3desc }}></p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default Prepare;
