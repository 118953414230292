import shop1 from "../img/onlinestores/fp_shop_1.webp";
import shop2 from "../img/retailstores/fp_shop_2.png";
import shop3 from "../img/retailstores/fp_shop_3.png";
import shop4 from "../img/retailstores/fp_shop_4.png";

export const onlinestoreData = [
  {
    img: shop1,
  },
];

export const retailstoresData = [
  {
    img: shop2,
    link: "https://www.fairprice.com.sg/search?query=first%20pride",
  },
  {
    img: shop3,
    link: "https://coldstorage.com.sg/search?q=first%20pride",
  },
  {
    img: shop4,
    link: "https://giant.sg/search?q=first+pride",
  },
];
